.mainNav ul {
    @apply space-x-4;
}

body {
    @apply antialiased bg-samGrey;
}

html {
    @apply bg-samGrey;
}


.projectBlock:hover .projectOverlay  {
    @apply opacity-100;
}

.projectBlock:hover .projectOverlayHighlight  {
    @apply opacity-40;
}

.aboutAddress * {
    @apply m-0;
}

.largeHeightGridItem {
    height: calc(((100vw - 5rem)) * 1.5);
}

.smallHeightGridItem {
    height: calc(((100vw - 5rem)) * 0.75);
}

@media (min-width: 768px) {
    .largeHeightGridItem {
        height: calc(((100vw - 13rem)/2) * 1.5);
    }

    .smallHeightGridItem {
        height: calc(((100vw - 13rem)/2) * 0.75);
    }
}

@media (min-width: 900px) {

    .largeHeightGridItem {
        height: calc(((100vw - 14.5rem)/3) * 1.5);
    }

    .smallHeightGridItem {
        height: calc(((100vw - 14.5rem)/3) * 0.75);
    }

}



/* purgecss start ignore */

.twic-blur {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
  }

  .twic-done {
    opacity: 1;
  }

:root {
    --plyr-color-main: #5200A7;
}

.embedded-asset iframe {
    @apply w-full max-h-[70vh];
}

/* purgecss end ignore */